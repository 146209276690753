import {
  HomeIcon,
  UserIcon,
  BookIcon,
  MoneyIcon,
} from "vseth-canine-ui";
import { useTranslation } from "react-i18next";

/**
 * The Navbar component displays the top navigation bar of the application.
 */
export const Navbar = () => {
  const { t } = useTranslation();


  const navbarConfig = [
    {
      key: 1,
      title: t("Navbar.home"),
      icon: HomeIcon,
      href: "/",
    },
    {
      key: 2,
      title: t("Navbar.account"),
      icon: UserIcon,
      href: "/account",
    },
    {
      key: 2,
      title: t("Navbar.guthaben"),
      icon: MoneyIcon,
      href: "/pay/guthaben",
    },
    {
      key: 3,
      title: t("Navbar.legal"),
      icon: BookIcon,
      href: "/legal",
    },
  ];

  return navbarConfig
};
