import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";

import store from "./app/store";

import * as serviceWorker from "./serviceWorker";
import "./locales/i18n";
import App from "./app/App";


const root = createRoot(
  document.getElementById("root") as HTMLElement
)


root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
