import { useKeycloak } from "@react-keycloak/web";
import { useDebugValue, useContext } from "react";
import { AuthContext } from "./AuthProvider";
import { getAuthStateFromToken } from "./authSlice";

export const DefaultAuthState = {
	isAuthenticated: false,
};

/**
 * React Hook for Auth with Keycloak in VSETH, uses the useKeycloak hook from https://github.com/panz3r/react-keycloak
 * and adds some useful functions to it (getRoles, hasRoles)
 * @returns Object with useful functions for Auth
 */

export const useAuth = () => {
	const { keycloak } = useKeycloak();

	const { authState, setAuthState } = useContext(AuthContext);

	const logout = () => {
		setAuthState(getAuthStateFromToken());
		keycloak.logout();
	};

	const login = keycloak.login;

	useDebugValue(authState);

	return { keycloak, authState, logout, login };
};
