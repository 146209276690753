/* eslint-disable */ 
// source: vseth/sip/guthaben/guthaben.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var vseth_type_money_pb = require('../../../vseth/type/money_pb.js');
goog.object.extend(proto, vseth_type_money_pb);
goog.exportSymbol('proto.vseth.sip.guthaben.Account', null, global);
goog.exportSymbol('proto.vseth.sip.guthaben.ConfirmTransactionRequest', null, global);
goog.exportSymbol('proto.vseth.sip.guthaben.CreateAccountRequest', null, global);
goog.exportSymbol('proto.vseth.sip.guthaben.CreatePayOutRequest', null, global);
goog.exportSymbol('proto.vseth.sip.guthaben.CreateTransactionRequest', null, global);
goog.exportSymbol('proto.vseth.sip.guthaben.GetAccountRequest', null, global);
goog.exportSymbol('proto.vseth.sip.guthaben.GetTransactionRequest', null, global);
goog.exportSymbol('proto.vseth.sip.guthaben.ListAccountsRequest', null, global);
goog.exportSymbol('proto.vseth.sip.guthaben.ListAccountsResponse', null, global);
goog.exportSymbol('proto.vseth.sip.guthaben.ListTransactionsRequest', null, global);
goog.exportSymbol('proto.vseth.sip.guthaben.ListTransactionsResponse', null, global);
goog.exportSymbol('proto.vseth.sip.guthaben.Transaction', null, global);
goog.exportSymbol('proto.vseth.sip.guthaben.Transaction.ReferenceCase', null, global);
goog.exportSymbol('proto.vseth.sip.guthaben.Transaction.Status', null, global);
goog.exportSymbol('proto.vseth.sip.guthaben.Transaction.Type', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.guthaben.ListTransactionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.guthaben.ListTransactionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.guthaben.ListTransactionsRequest.displayName = 'proto.vseth.sip.guthaben.ListTransactionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.guthaben.ListTransactionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vseth.sip.guthaben.ListTransactionsResponse.repeatedFields_, null);
};
goog.inherits(proto.vseth.sip.guthaben.ListTransactionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.guthaben.ListTransactionsResponse.displayName = 'proto.vseth.sip.guthaben.ListTransactionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.guthaben.GetTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.guthaben.GetTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.guthaben.GetTransactionRequest.displayName = 'proto.vseth.sip.guthaben.GetTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.guthaben.CreateTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.guthaben.CreateTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.guthaben.CreateTransactionRequest.displayName = 'proto.vseth.sip.guthaben.CreateTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.guthaben.ConfirmTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.guthaben.ConfirmTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.guthaben.ConfirmTransactionRequest.displayName = 'proto.vseth.sip.guthaben.ConfirmTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.guthaben.Transaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.vseth.sip.guthaben.Transaction.oneofGroups_);
};
goog.inherits(proto.vseth.sip.guthaben.Transaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.guthaben.Transaction.displayName = 'proto.vseth.sip.guthaben.Transaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.guthaben.ListAccountsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.guthaben.ListAccountsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.guthaben.ListAccountsRequest.displayName = 'proto.vseth.sip.guthaben.ListAccountsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.guthaben.ListAccountsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vseth.sip.guthaben.ListAccountsResponse.repeatedFields_, null);
};
goog.inherits(proto.vseth.sip.guthaben.ListAccountsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.guthaben.ListAccountsResponse.displayName = 'proto.vseth.sip.guthaben.ListAccountsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.guthaben.GetAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.guthaben.GetAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.guthaben.GetAccountRequest.displayName = 'proto.vseth.sip.guthaben.GetAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.guthaben.CreateAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.guthaben.CreateAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.guthaben.CreateAccountRequest.displayName = 'proto.vseth.sip.guthaben.CreateAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.guthaben.Account = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.guthaben.Account, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.guthaben.Account.displayName = 'proto.vseth.sip.guthaben.Account';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.guthaben.CreatePayOutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.guthaben.CreatePayOutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.guthaben.CreatePayOutRequest.displayName = 'proto.vseth.sip.guthaben.CreatePayOutRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.guthaben.ListTransactionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.guthaben.ListTransactionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.guthaben.ListTransactionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.guthaben.ListTransactionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    account: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filter: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orderBy: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pageToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pageSize: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.guthaben.ListTransactionsRequest}
 */
proto.vseth.sip.guthaben.ListTransactionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.guthaben.ListTransactionsRequest;
  return proto.vseth.sip.guthaben.ListTransactionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.guthaben.ListTransactionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.guthaben.ListTransactionsRequest}
 */
proto.vseth.sip.guthaben.ListTransactionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilter(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderBy(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.guthaben.ListTransactionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.guthaben.ListTransactionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.guthaben.ListTransactionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.guthaben.ListTransactionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilter();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrderBy();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string account = 1;
 * @return {string}
 */
proto.vseth.sip.guthaben.ListTransactionsRequest.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.guthaben.ListTransactionsRequest} returns this
 */
proto.vseth.sip.guthaben.ListTransactionsRequest.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string filter = 2;
 * @return {string}
 */
proto.vseth.sip.guthaben.ListTransactionsRequest.prototype.getFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.guthaben.ListTransactionsRequest} returns this
 */
proto.vseth.sip.guthaben.ListTransactionsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string order_by = 3;
 * @return {string}
 */
proto.vseth.sip.guthaben.ListTransactionsRequest.prototype.getOrderBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.guthaben.ListTransactionsRequest} returns this
 */
proto.vseth.sip.guthaben.ListTransactionsRequest.prototype.setOrderBy = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string page_token = 4;
 * @return {string}
 */
proto.vseth.sip.guthaben.ListTransactionsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.guthaben.ListTransactionsRequest} returns this
 */
proto.vseth.sip.guthaben.ListTransactionsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 page_size = 5;
 * @return {number}
 */
proto.vseth.sip.guthaben.ListTransactionsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.guthaben.ListTransactionsRequest} returns this
 */
proto.vseth.sip.guthaben.ListTransactionsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vseth.sip.guthaben.ListTransactionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.guthaben.ListTransactionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.guthaben.ListTransactionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.guthaben.ListTransactionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.guthaben.ListTransactionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    proto.vseth.sip.guthaben.Transaction.toObject, includeInstance),
    totalSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.guthaben.ListTransactionsResponse}
 */
proto.vseth.sip.guthaben.ListTransactionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.guthaben.ListTransactionsResponse;
  return proto.vseth.sip.guthaben.ListTransactionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.guthaben.ListTransactionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.guthaben.ListTransactionsResponse}
 */
proto.vseth.sip.guthaben.ListTransactionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vseth.sip.guthaben.Transaction;
      reader.readMessage(value,proto.vseth.sip.guthaben.Transaction.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.guthaben.ListTransactionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.guthaben.ListTransactionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.guthaben.ListTransactionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.guthaben.ListTransactionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vseth.sip.guthaben.Transaction.serializeBinaryToWriter
    );
  }
  f = message.getTotalSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated Transaction transactions = 1;
 * @return {!Array<!proto.vseth.sip.guthaben.Transaction>}
 */
proto.vseth.sip.guthaben.ListTransactionsResponse.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.vseth.sip.guthaben.Transaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vseth.sip.guthaben.Transaction, 1));
};


/**
 * @param {!Array<!proto.vseth.sip.guthaben.Transaction>} value
 * @return {!proto.vseth.sip.guthaben.ListTransactionsResponse} returns this
*/
proto.vseth.sip.guthaben.ListTransactionsResponse.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vseth.sip.guthaben.Transaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vseth.sip.guthaben.Transaction}
 */
proto.vseth.sip.guthaben.ListTransactionsResponse.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vseth.sip.guthaben.Transaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vseth.sip.guthaben.ListTransactionsResponse} returns this
 */
proto.vseth.sip.guthaben.ListTransactionsResponse.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};


/**
 * optional int32 total_size = 2;
 * @return {number}
 */
proto.vseth.sip.guthaben.ListTransactionsResponse.prototype.getTotalSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.guthaben.ListTransactionsResponse} returns this
 */
proto.vseth.sip.guthaben.ListTransactionsResponse.prototype.setTotalSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string next_page_token = 3;
 * @return {string}
 */
proto.vseth.sip.guthaben.ListTransactionsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.guthaben.ListTransactionsResponse} returns this
 */
proto.vseth.sip.guthaben.ListTransactionsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.guthaben.GetTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.guthaben.GetTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.guthaben.GetTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.guthaben.GetTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    transaction: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.guthaben.GetTransactionRequest}
 */
proto.vseth.sip.guthaben.GetTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.guthaben.GetTransactionRequest;
  return proto.vseth.sip.guthaben.GetTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.guthaben.GetTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.guthaben.GetTransactionRequest}
 */
proto.vseth.sip.guthaben.GetTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransaction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.guthaben.GetTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.guthaben.GetTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.guthaben.GetTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.guthaben.GetTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransaction();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string transaction = 1;
 * @return {string}
 */
proto.vseth.sip.guthaben.GetTransactionRequest.prototype.getTransaction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.guthaben.GetTransactionRequest} returns this
 */
proto.vseth.sip.guthaben.GetTransactionRequest.prototype.setTransaction = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.guthaben.CreateTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.guthaben.CreateTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.guthaben.CreateTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.guthaben.CreateTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    transaction: (f = msg.getTransaction()) && proto.vseth.sip.guthaben.Transaction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.guthaben.CreateTransactionRequest}
 */
proto.vseth.sip.guthaben.CreateTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.guthaben.CreateTransactionRequest;
  return proto.vseth.sip.guthaben.CreateTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.guthaben.CreateTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.guthaben.CreateTransactionRequest}
 */
proto.vseth.sip.guthaben.CreateTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vseth.sip.guthaben.Transaction;
      reader.readMessage(value,proto.vseth.sip.guthaben.Transaction.deserializeBinaryFromReader);
      msg.setTransaction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.guthaben.CreateTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.guthaben.CreateTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.guthaben.CreateTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.guthaben.CreateTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransaction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vseth.sip.guthaben.Transaction.serializeBinaryToWriter
    );
  }
};


/**
 * optional Transaction transaction = 1;
 * @return {?proto.vseth.sip.guthaben.Transaction}
 */
proto.vseth.sip.guthaben.CreateTransactionRequest.prototype.getTransaction = function() {
  return /** @type{?proto.vseth.sip.guthaben.Transaction} */ (
    jspb.Message.getWrapperField(this, proto.vseth.sip.guthaben.Transaction, 1));
};


/**
 * @param {?proto.vseth.sip.guthaben.Transaction|undefined} value
 * @return {!proto.vseth.sip.guthaben.CreateTransactionRequest} returns this
*/
proto.vseth.sip.guthaben.CreateTransactionRequest.prototype.setTransaction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.guthaben.CreateTransactionRequest} returns this
 */
proto.vseth.sip.guthaben.CreateTransactionRequest.prototype.clearTransaction = function() {
  return this.setTransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.guthaben.CreateTransactionRequest.prototype.hasTransaction = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.guthaben.ConfirmTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.guthaben.ConfirmTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.guthaben.ConfirmTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.guthaben.ConfirmTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    transaction: jspb.Message.getFieldWithDefault(msg, 1, ""),
    account: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.guthaben.ConfirmTransactionRequest}
 */
proto.vseth.sip.guthaben.ConfirmTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.guthaben.ConfirmTransactionRequest;
  return proto.vseth.sip.guthaben.ConfirmTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.guthaben.ConfirmTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.guthaben.ConfirmTransactionRequest}
 */
proto.vseth.sip.guthaben.ConfirmTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransaction(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.guthaben.ConfirmTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.guthaben.ConfirmTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.guthaben.ConfirmTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.guthaben.ConfirmTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransaction();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string transaction = 1;
 * @return {string}
 */
proto.vseth.sip.guthaben.ConfirmTransactionRequest.prototype.getTransaction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.guthaben.ConfirmTransactionRequest} returns this
 */
proto.vseth.sip.guthaben.ConfirmTransactionRequest.prototype.setTransaction = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account = 2;
 * @return {string}
 */
proto.vseth.sip.guthaben.ConfirmTransactionRequest.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.guthaben.ConfirmTransactionRequest} returns this
 */
proto.vseth.sip.guthaben.ConfirmTransactionRequest.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.vseth.sip.guthaben.Transaction.oneofGroups_ = [[6,7,8]];

/**
 * @enum {number}
 */
proto.vseth.sip.guthaben.Transaction.ReferenceCase = {
  REFERENCE_NOT_SET: 0,
  ORDER: 6,
  GATEWAY: 7,
  BILL: 8
};

/**
 * @return {proto.vseth.sip.guthaben.Transaction.ReferenceCase}
 */
proto.vseth.sip.guthaben.Transaction.prototype.getReferenceCase = function() {
  return /** @type {proto.vseth.sip.guthaben.Transaction.ReferenceCase} */(jspb.Message.computeOneofCase(this, proto.vseth.sip.guthaben.Transaction.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.guthaben.Transaction.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.guthaben.Transaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.guthaben.Transaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.guthaben.Transaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    account: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amount: (f = msg.getAmount()) && vseth_type_money_pb.Money.toObject(includeInstance, f),
    expireTime: (f = msg.getExpireTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    order: jspb.Message.getFieldWithDefault(msg, 6, ""),
    gateway: jspb.Message.getFieldWithDefault(msg, 7, ""),
    bill: jspb.Message.getFieldWithDefault(msg, 8, ""),
    type: jspb.Message.getFieldWithDefault(msg, 9, 0),
    status: jspb.Message.getFieldWithDefault(msg, 10, 0),
    checkoutUrl: jspb.Message.getFieldWithDefault(msg, 13, ""),
    url: jspb.Message.getFieldWithDefault(msg, 14, ""),
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updateTime: (f = msg.getUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.guthaben.Transaction}
 */
proto.vseth.sip.guthaben.Transaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.guthaben.Transaction;
  return proto.vseth.sip.guthaben.Transaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.guthaben.Transaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.guthaben.Transaction}
 */
proto.vseth.sip.guthaben.Transaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 3:
      var value = new vseth_type_money_pb.Money;
      reader.readMessage(value,vseth_type_money_pb.Money.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpireTime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrder(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setGateway(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBill(value);
      break;
    case 9:
      var value = /** @type {!proto.vseth.sip.guthaben.Transaction.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 10:
      var value = /** @type {!proto.vseth.sip.guthaben.Transaction.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckoutUrl(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.guthaben.Transaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.guthaben.Transaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.guthaben.Transaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.guthaben.Transaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vseth_type_money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getExpireTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getCheckoutUrl();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTime();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vseth.sip.guthaben.Transaction.Status = {
  UNCONFIRMED: 0,
  CONFIRMED: 1,
  CANCELLED: 2
};

/**
 * @enum {number}
 */
proto.vseth.sip.guthaben.Transaction.Type = {
  SPEND: 0,
  TOPUP: 1,
  REFUND: 2,
  PAYOUT: 3
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.vseth.sip.guthaben.Transaction.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.guthaben.Transaction} returns this
 */
proto.vseth.sip.guthaben.Transaction.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account = 2;
 * @return {string}
 */
proto.vseth.sip.guthaben.Transaction.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.guthaben.Transaction} returns this
 */
proto.vseth.sip.guthaben.Transaction.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional vseth.type.Money amount = 3;
 * @return {?proto.vseth.type.Money}
 */
proto.vseth.sip.guthaben.Transaction.prototype.getAmount = function() {
  return /** @type{?proto.vseth.type.Money} */ (
    jspb.Message.getWrapperField(this, vseth_type_money_pb.Money, 3));
};


/**
 * @param {?proto.vseth.type.Money|undefined} value
 * @return {!proto.vseth.sip.guthaben.Transaction} returns this
*/
proto.vseth.sip.guthaben.Transaction.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.guthaben.Transaction} returns this
 */
proto.vseth.sip.guthaben.Transaction.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.guthaben.Transaction.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp expire_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.guthaben.Transaction.prototype.getExpireTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.guthaben.Transaction} returns this
*/
proto.vseth.sip.guthaben.Transaction.prototype.setExpireTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.guthaben.Transaction} returns this
 */
proto.vseth.sip.guthaben.Transaction.prototype.clearExpireTime = function() {
  return this.setExpireTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.guthaben.Transaction.prototype.hasExpireTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string order = 6;
 * @return {string}
 */
proto.vseth.sip.guthaben.Transaction.prototype.getOrder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.guthaben.Transaction} returns this
 */
proto.vseth.sip.guthaben.Transaction.prototype.setOrder = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.vseth.sip.guthaben.Transaction.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vseth.sip.guthaben.Transaction} returns this
 */
proto.vseth.sip.guthaben.Transaction.prototype.clearOrder = function() {
  return jspb.Message.setOneofField(this, 6, proto.vseth.sip.guthaben.Transaction.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.guthaben.Transaction.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string gateway = 7;
 * @return {string}
 */
proto.vseth.sip.guthaben.Transaction.prototype.getGateway = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.guthaben.Transaction} returns this
 */
proto.vseth.sip.guthaben.Transaction.prototype.setGateway = function(value) {
  return jspb.Message.setOneofField(this, 7, proto.vseth.sip.guthaben.Transaction.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vseth.sip.guthaben.Transaction} returns this
 */
proto.vseth.sip.guthaben.Transaction.prototype.clearGateway = function() {
  return jspb.Message.setOneofField(this, 7, proto.vseth.sip.guthaben.Transaction.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.guthaben.Transaction.prototype.hasGateway = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string bill = 8;
 * @return {string}
 */
proto.vseth.sip.guthaben.Transaction.prototype.getBill = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.guthaben.Transaction} returns this
 */
proto.vseth.sip.guthaben.Transaction.prototype.setBill = function(value) {
  return jspb.Message.setOneofField(this, 8, proto.vseth.sip.guthaben.Transaction.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vseth.sip.guthaben.Transaction} returns this
 */
proto.vseth.sip.guthaben.Transaction.prototype.clearBill = function() {
  return jspb.Message.setOneofField(this, 8, proto.vseth.sip.guthaben.Transaction.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.guthaben.Transaction.prototype.hasBill = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Type type = 9;
 * @return {!proto.vseth.sip.guthaben.Transaction.Type}
 */
proto.vseth.sip.guthaben.Transaction.prototype.getType = function() {
  return /** @type {!proto.vseth.sip.guthaben.Transaction.Type} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.vseth.sip.guthaben.Transaction.Type} value
 * @return {!proto.vseth.sip.guthaben.Transaction} returns this
 */
proto.vseth.sip.guthaben.Transaction.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional Status status = 10;
 * @return {!proto.vseth.sip.guthaben.Transaction.Status}
 */
proto.vseth.sip.guthaben.Transaction.prototype.getStatus = function() {
  return /** @type {!proto.vseth.sip.guthaben.Transaction.Status} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.vseth.sip.guthaben.Transaction.Status} value
 * @return {!proto.vseth.sip.guthaben.Transaction} returns this
 */
proto.vseth.sip.guthaben.Transaction.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string checkout_url = 13;
 * @return {string}
 */
proto.vseth.sip.guthaben.Transaction.prototype.getCheckoutUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.guthaben.Transaction} returns this
 */
proto.vseth.sip.guthaben.Transaction.prototype.setCheckoutUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string url = 14;
 * @return {string}
 */
proto.vseth.sip.guthaben.Transaction.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.guthaben.Transaction} returns this
 */
proto.vseth.sip.guthaben.Transaction.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional google.protobuf.Timestamp create_time = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.guthaben.Transaction.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.guthaben.Transaction} returns this
*/
proto.vseth.sip.guthaben.Transaction.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.guthaben.Transaction} returns this
 */
proto.vseth.sip.guthaben.Transaction.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.guthaben.Transaction.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Timestamp update_time = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.guthaben.Transaction.prototype.getUpdateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.guthaben.Transaction} returns this
*/
proto.vseth.sip.guthaben.Transaction.prototype.setUpdateTime = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.guthaben.Transaction} returns this
 */
proto.vseth.sip.guthaben.Transaction.prototype.clearUpdateTime = function() {
  return this.setUpdateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.guthaben.Transaction.prototype.hasUpdateTime = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.guthaben.ListAccountsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.guthaben.ListAccountsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.guthaben.ListAccountsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.guthaben.ListAccountsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderBy: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pageToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pageSize: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.guthaben.ListAccountsRequest}
 */
proto.vseth.sip.guthaben.ListAccountsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.guthaben.ListAccountsRequest;
  return proto.vseth.sip.guthaben.ListAccountsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.guthaben.ListAccountsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.guthaben.ListAccountsRequest}
 */
proto.vseth.sip.guthaben.ListAccountsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilter(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderBy(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.guthaben.ListAccountsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.guthaben.ListAccountsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.guthaben.ListAccountsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.guthaben.ListAccountsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderBy();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string filter = 1;
 * @return {string}
 */
proto.vseth.sip.guthaben.ListAccountsRequest.prototype.getFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.guthaben.ListAccountsRequest} returns this
 */
proto.vseth.sip.guthaben.ListAccountsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string order_by = 2;
 * @return {string}
 */
proto.vseth.sip.guthaben.ListAccountsRequest.prototype.getOrderBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.guthaben.ListAccountsRequest} returns this
 */
proto.vseth.sip.guthaben.ListAccountsRequest.prototype.setOrderBy = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string page_token = 3;
 * @return {string}
 */
proto.vseth.sip.guthaben.ListAccountsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.guthaben.ListAccountsRequest} returns this
 */
proto.vseth.sip.guthaben.ListAccountsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 page_size = 5;
 * @return {number}
 */
proto.vseth.sip.guthaben.ListAccountsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.guthaben.ListAccountsRequest} returns this
 */
proto.vseth.sip.guthaben.ListAccountsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vseth.sip.guthaben.ListAccountsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.guthaben.ListAccountsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.guthaben.ListAccountsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.guthaben.ListAccountsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.guthaben.ListAccountsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountsList: jspb.Message.toObjectList(msg.getAccountsList(),
    proto.vseth.sip.guthaben.Account.toObject, includeInstance),
    totalSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.guthaben.ListAccountsResponse}
 */
proto.vseth.sip.guthaben.ListAccountsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.guthaben.ListAccountsResponse;
  return proto.vseth.sip.guthaben.ListAccountsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.guthaben.ListAccountsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.guthaben.ListAccountsResponse}
 */
proto.vseth.sip.guthaben.ListAccountsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vseth.sip.guthaben.Account;
      reader.readMessage(value,proto.vseth.sip.guthaben.Account.deserializeBinaryFromReader);
      msg.addAccounts(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.guthaben.ListAccountsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.guthaben.ListAccountsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.guthaben.ListAccountsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.guthaben.ListAccountsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vseth.sip.guthaben.Account.serializeBinaryToWriter
    );
  }
  f = message.getTotalSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated Account accounts = 1;
 * @return {!Array<!proto.vseth.sip.guthaben.Account>}
 */
proto.vseth.sip.guthaben.ListAccountsResponse.prototype.getAccountsList = function() {
  return /** @type{!Array<!proto.vseth.sip.guthaben.Account>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vseth.sip.guthaben.Account, 1));
};


/**
 * @param {!Array<!proto.vseth.sip.guthaben.Account>} value
 * @return {!proto.vseth.sip.guthaben.ListAccountsResponse} returns this
*/
proto.vseth.sip.guthaben.ListAccountsResponse.prototype.setAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vseth.sip.guthaben.Account=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vseth.sip.guthaben.Account}
 */
proto.vseth.sip.guthaben.ListAccountsResponse.prototype.addAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vseth.sip.guthaben.Account, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vseth.sip.guthaben.ListAccountsResponse} returns this
 */
proto.vseth.sip.guthaben.ListAccountsResponse.prototype.clearAccountsList = function() {
  return this.setAccountsList([]);
};


/**
 * optional int32 total_size = 2;
 * @return {number}
 */
proto.vseth.sip.guthaben.ListAccountsResponse.prototype.getTotalSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.guthaben.ListAccountsResponse} returns this
 */
proto.vseth.sip.guthaben.ListAccountsResponse.prototype.setTotalSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string next_page_token = 3;
 * @return {string}
 */
proto.vseth.sip.guthaben.ListAccountsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.guthaben.ListAccountsResponse} returns this
 */
proto.vseth.sip.guthaben.ListAccountsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.guthaben.GetAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.guthaben.GetAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.guthaben.GetAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.guthaben.GetAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    account: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.guthaben.GetAccountRequest}
 */
proto.vseth.sip.guthaben.GetAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.guthaben.GetAccountRequest;
  return proto.vseth.sip.guthaben.GetAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.guthaben.GetAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.guthaben.GetAccountRequest}
 */
proto.vseth.sip.guthaben.GetAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.guthaben.GetAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.guthaben.GetAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.guthaben.GetAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.guthaben.GetAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string account = 1;
 * @return {string}
 */
proto.vseth.sip.guthaben.GetAccountRequest.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.guthaben.GetAccountRequest} returns this
 */
proto.vseth.sip.guthaben.GetAccountRequest.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.guthaben.CreateAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.guthaben.CreateAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.guthaben.CreateAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.guthaben.CreateAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    account: (f = msg.getAccount()) && proto.vseth.sip.guthaben.Account.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.guthaben.CreateAccountRequest}
 */
proto.vseth.sip.guthaben.CreateAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.guthaben.CreateAccountRequest;
  return proto.vseth.sip.guthaben.CreateAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.guthaben.CreateAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.guthaben.CreateAccountRequest}
 */
proto.vseth.sip.guthaben.CreateAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vseth.sip.guthaben.Account;
      reader.readMessage(value,proto.vseth.sip.guthaben.Account.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.guthaben.CreateAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.guthaben.CreateAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.guthaben.CreateAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.guthaben.CreateAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vseth.sip.guthaben.Account.serializeBinaryToWriter
    );
  }
};


/**
 * optional Account account = 1;
 * @return {?proto.vseth.sip.guthaben.Account}
 */
proto.vseth.sip.guthaben.CreateAccountRequest.prototype.getAccount = function() {
  return /** @type{?proto.vseth.sip.guthaben.Account} */ (
    jspb.Message.getWrapperField(this, proto.vseth.sip.guthaben.Account, 1));
};


/**
 * @param {?proto.vseth.sip.guthaben.Account|undefined} value
 * @return {!proto.vseth.sip.guthaben.CreateAccountRequest} returns this
*/
proto.vseth.sip.guthaben.CreateAccountRequest.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.guthaben.CreateAccountRequest} returns this
 */
proto.vseth.sip.guthaben.CreateAccountRequest.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.guthaben.CreateAccountRequest.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.guthaben.Account.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.guthaben.Account.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.guthaben.Account} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.guthaben.Account.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sub: jspb.Message.getFieldWithDefault(msg, 2, ""),
    balance: (f = msg.getBalance()) && vseth_type_money_pb.Money.toObject(includeInstance, f),
    calculationTime: (f = msg.getCalculationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    hasMustPayout: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    mustPayoutDate: (f = msg.getMustPayoutDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updateTime: (f = msg.getUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.guthaben.Account}
 */
proto.vseth.sip.guthaben.Account.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.guthaben.Account;
  return proto.vseth.sip.guthaben.Account.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.guthaben.Account} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.guthaben.Account}
 */
proto.vseth.sip.guthaben.Account.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSub(value);
      break;
    case 3:
      var value = new vseth_type_money_pb.Money;
      reader.readMessage(value,vseth_type_money_pb.Money.deserializeBinaryFromReader);
      msg.setBalance(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCalculationTime(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasMustPayout(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setMustPayoutDate(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.guthaben.Account.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.guthaben.Account.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.guthaben.Account} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.guthaben.Account.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSub();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBalance();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vseth_type_money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getCalculationTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getHasMustPayout();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getMustPayoutDate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vseth.sip.guthaben.Account.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.guthaben.Account} returns this
 */
proto.vseth.sip.guthaben.Account.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sub = 2;
 * @return {string}
 */
proto.vseth.sip.guthaben.Account.prototype.getSub = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.guthaben.Account} returns this
 */
proto.vseth.sip.guthaben.Account.prototype.setSub = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional vseth.type.Money balance = 3;
 * @return {?proto.vseth.type.Money}
 */
proto.vseth.sip.guthaben.Account.prototype.getBalance = function() {
  return /** @type{?proto.vseth.type.Money} */ (
    jspb.Message.getWrapperField(this, vseth_type_money_pb.Money, 3));
};


/**
 * @param {?proto.vseth.type.Money|undefined} value
 * @return {!proto.vseth.sip.guthaben.Account} returns this
*/
proto.vseth.sip.guthaben.Account.prototype.setBalance = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.guthaben.Account} returns this
 */
proto.vseth.sip.guthaben.Account.prototype.clearBalance = function() {
  return this.setBalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.guthaben.Account.prototype.hasBalance = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp calculation_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.guthaben.Account.prototype.getCalculationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.guthaben.Account} returns this
*/
proto.vseth.sip.guthaben.Account.prototype.setCalculationTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.guthaben.Account} returns this
 */
proto.vseth.sip.guthaben.Account.prototype.clearCalculationTime = function() {
  return this.setCalculationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.guthaben.Account.prototype.hasCalculationTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool has_must_payout = 5;
 * @return {boolean}
 */
proto.vseth.sip.guthaben.Account.prototype.getHasMustPayout = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vseth.sip.guthaben.Account} returns this
 */
proto.vseth.sip.guthaben.Account.prototype.setHasMustPayout = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp must_payout_date = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.guthaben.Account.prototype.getMustPayoutDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.guthaben.Account} returns this
*/
proto.vseth.sip.guthaben.Account.prototype.setMustPayoutDate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.guthaben.Account} returns this
 */
proto.vseth.sip.guthaben.Account.prototype.clearMustPayoutDate = function() {
  return this.setMustPayoutDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.guthaben.Account.prototype.hasMustPayoutDate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp create_time = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.guthaben.Account.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.guthaben.Account} returns this
*/
proto.vseth.sip.guthaben.Account.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.guthaben.Account} returns this
 */
proto.vseth.sip.guthaben.Account.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.guthaben.Account.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp update_time = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.guthaben.Account.prototype.getUpdateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.guthaben.Account} returns this
*/
proto.vseth.sip.guthaben.Account.prototype.setUpdateTime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.guthaben.Account} returns this
 */
proto.vseth.sip.guthaben.Account.prototype.clearUpdateTime = function() {
  return this.setUpdateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.guthaben.Account.prototype.hasUpdateTime = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.guthaben.CreatePayOutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.guthaben.CreatePayOutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.guthaben.CreatePayOutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.guthaben.CreatePayOutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    account: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amount: (f = msg.getAmount()) && vseth_type_money_pb.Money.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.guthaben.CreatePayOutRequest}
 */
proto.vseth.sip.guthaben.CreatePayOutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.guthaben.CreatePayOutRequest;
  return proto.vseth.sip.guthaben.CreatePayOutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.guthaben.CreatePayOutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.guthaben.CreatePayOutRequest}
 */
proto.vseth.sip.guthaben.CreatePayOutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 2:
      var value = new vseth_type_money_pb.Money;
      reader.readMessage(value,vseth_type_money_pb.Money.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.guthaben.CreatePayOutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.guthaben.CreatePayOutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.guthaben.CreatePayOutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.guthaben.CreatePayOutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vseth_type_money_pb.Money.serializeBinaryToWriter
    );
  }
};


/**
 * optional string account = 1;
 * @return {string}
 */
proto.vseth.sip.guthaben.CreatePayOutRequest.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.guthaben.CreatePayOutRequest} returns this
 */
proto.vseth.sip.guthaben.CreatePayOutRequest.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional vseth.type.Money amount = 2;
 * @return {?proto.vseth.type.Money}
 */
proto.vseth.sip.guthaben.CreatePayOutRequest.prototype.getAmount = function() {
  return /** @type{?proto.vseth.type.Money} */ (
    jspb.Message.getWrapperField(this, vseth_type_money_pb.Money, 2));
};


/**
 * @param {?proto.vseth.type.Money|undefined} value
 * @return {!proto.vseth.sip.guthaben.CreatePayOutRequest} returns this
*/
proto.vseth.sip.guthaben.CreatePayOutRequest.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.guthaben.CreatePayOutRequest} returns this
 */
proto.vseth.sip.guthaben.CreatePayOutRequest.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.guthaben.CreatePayOutRequest.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.vseth.sip.guthaben);
