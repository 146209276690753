import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    selectIsAuthenticated,
} from "../features/auth/authSlice";
import { useTranslation } from "react-i18next";
import { Container } from "@mantine/core";

/**
 * TransactionsPage displays all transactions of a user.
 */
export const TransactionsPage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <section className="mb-5">
                <h1>{t("TransactionsPage.title")}</h1>
            </section>
        </Container>
    );
};
