/* eslint-disable */ 
/**
 * @fileoverview gRPC-Web generated client stub for vseth.sip.guthaben
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: vseth/sip/guthaben/guthaben.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var vseth_type_money_pb = require('../../../vseth/type/money_pb.js')
const proto = {};
proto.vseth = {};
proto.vseth.sip = {};
proto.vseth.sip.guthaben = require('./guthaben_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.vseth.sip.guthaben.GuthabenClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.vseth.sip.guthaben.GuthabenPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.vseth.sip.guthaben.ListTransactionsRequest,
 *   !proto.vseth.sip.guthaben.ListTransactionsResponse>}
 */
const methodDescriptor_Guthaben_ListTransactions = new grpc.web.MethodDescriptor(
  '/vseth.sip.guthaben.Guthaben/ListTransactions',
  grpc.web.MethodType.UNARY,
  proto.vseth.sip.guthaben.ListTransactionsRequest,
  proto.vseth.sip.guthaben.ListTransactionsResponse,
  /**
   * @param {!proto.vseth.sip.guthaben.ListTransactionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vseth.sip.guthaben.ListTransactionsResponse.deserializeBinary
);


/**
 * @param {!proto.vseth.sip.guthaben.ListTransactionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.vseth.sip.guthaben.ListTransactionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.vseth.sip.guthaben.ListTransactionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.vseth.sip.guthaben.GuthabenClient.prototype.listTransactions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/vseth.sip.guthaben.Guthaben/ListTransactions',
      request,
      metadata || {},
      methodDescriptor_Guthaben_ListTransactions,
      callback);
};


/**
 * @param {!proto.vseth.sip.guthaben.ListTransactionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.vseth.sip.guthaben.ListTransactionsResponse>}
 *     Promise that resolves to the response
 */
proto.vseth.sip.guthaben.GuthabenPromiseClient.prototype.listTransactions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/vseth.sip.guthaben.Guthaben/ListTransactions',
      request,
      metadata || {},
      methodDescriptor_Guthaben_ListTransactions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.vseth.sip.guthaben.GetTransactionRequest,
 *   !proto.vseth.sip.guthaben.Transaction>}
 */
const methodDescriptor_Guthaben_GetTransaction = new grpc.web.MethodDescriptor(
  '/vseth.sip.guthaben.Guthaben/GetTransaction',
  grpc.web.MethodType.UNARY,
  proto.vseth.sip.guthaben.GetTransactionRequest,
  proto.vseth.sip.guthaben.Transaction,
  /**
   * @param {!proto.vseth.sip.guthaben.GetTransactionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vseth.sip.guthaben.Transaction.deserializeBinary
);


/**
 * @param {!proto.vseth.sip.guthaben.GetTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.vseth.sip.guthaben.Transaction)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.vseth.sip.guthaben.Transaction>|undefined}
 *     The XHR Node Readable Stream
 */
proto.vseth.sip.guthaben.GuthabenClient.prototype.getTransaction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/vseth.sip.guthaben.Guthaben/GetTransaction',
      request,
      metadata || {},
      methodDescriptor_Guthaben_GetTransaction,
      callback);
};


/**
 * @param {!proto.vseth.sip.guthaben.GetTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.vseth.sip.guthaben.Transaction>}
 *     Promise that resolves to the response
 */
proto.vseth.sip.guthaben.GuthabenPromiseClient.prototype.getTransaction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/vseth.sip.guthaben.Guthaben/GetTransaction',
      request,
      metadata || {},
      methodDescriptor_Guthaben_GetTransaction);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.vseth.sip.guthaben.CreateTransactionRequest,
 *   !proto.vseth.sip.guthaben.Transaction>}
 */
const methodDescriptor_Guthaben_CreateTransaction = new grpc.web.MethodDescriptor(
  '/vseth.sip.guthaben.Guthaben/CreateTransaction',
  grpc.web.MethodType.UNARY,
  proto.vseth.sip.guthaben.CreateTransactionRequest,
  proto.vseth.sip.guthaben.Transaction,
  /**
   * @param {!proto.vseth.sip.guthaben.CreateTransactionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vseth.sip.guthaben.Transaction.deserializeBinary
);


/**
 * @param {!proto.vseth.sip.guthaben.CreateTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.vseth.sip.guthaben.Transaction)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.vseth.sip.guthaben.Transaction>|undefined}
 *     The XHR Node Readable Stream
 */
proto.vseth.sip.guthaben.GuthabenClient.prototype.createTransaction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/vseth.sip.guthaben.Guthaben/CreateTransaction',
      request,
      metadata || {},
      methodDescriptor_Guthaben_CreateTransaction,
      callback);
};


/**
 * @param {!proto.vseth.sip.guthaben.CreateTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.vseth.sip.guthaben.Transaction>}
 *     Promise that resolves to the response
 */
proto.vseth.sip.guthaben.GuthabenPromiseClient.prototype.createTransaction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/vseth.sip.guthaben.Guthaben/CreateTransaction',
      request,
      metadata || {},
      methodDescriptor_Guthaben_CreateTransaction);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.vseth.sip.guthaben.ConfirmTransactionRequest,
 *   !proto.vseth.sip.guthaben.Transaction>}
 */
const methodDescriptor_Guthaben_ConfirmTransaction = new grpc.web.MethodDescriptor(
  '/vseth.sip.guthaben.Guthaben/ConfirmTransaction',
  grpc.web.MethodType.UNARY,
  proto.vseth.sip.guthaben.ConfirmTransactionRequest,
  proto.vseth.sip.guthaben.Transaction,
  /**
   * @param {!proto.vseth.sip.guthaben.ConfirmTransactionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vseth.sip.guthaben.Transaction.deserializeBinary
);


/**
 * @param {!proto.vseth.sip.guthaben.ConfirmTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.vseth.sip.guthaben.Transaction)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.vseth.sip.guthaben.Transaction>|undefined}
 *     The XHR Node Readable Stream
 */
proto.vseth.sip.guthaben.GuthabenClient.prototype.confirmTransaction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/vseth.sip.guthaben.Guthaben/ConfirmTransaction',
      request,
      metadata || {},
      methodDescriptor_Guthaben_ConfirmTransaction,
      callback);
};


/**
 * @param {!proto.vseth.sip.guthaben.ConfirmTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.vseth.sip.guthaben.Transaction>}
 *     Promise that resolves to the response
 */
proto.vseth.sip.guthaben.GuthabenPromiseClient.prototype.confirmTransaction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/vseth.sip.guthaben.Guthaben/ConfirmTransaction',
      request,
      metadata || {},
      methodDescriptor_Guthaben_ConfirmTransaction);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.vseth.sip.guthaben.ListAccountsRequest,
 *   !proto.vseth.sip.guthaben.ListAccountsResponse>}
 */
const methodDescriptor_Guthaben_ListAccounts = new grpc.web.MethodDescriptor(
  '/vseth.sip.guthaben.Guthaben/ListAccounts',
  grpc.web.MethodType.UNARY,
  proto.vseth.sip.guthaben.ListAccountsRequest,
  proto.vseth.sip.guthaben.ListAccountsResponse,
  /**
   * @param {!proto.vseth.sip.guthaben.ListAccountsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vseth.sip.guthaben.ListAccountsResponse.deserializeBinary
);


/**
 * @param {!proto.vseth.sip.guthaben.ListAccountsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.vseth.sip.guthaben.ListAccountsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.vseth.sip.guthaben.ListAccountsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.vseth.sip.guthaben.GuthabenClient.prototype.listAccounts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/vseth.sip.guthaben.Guthaben/ListAccounts',
      request,
      metadata || {},
      methodDescriptor_Guthaben_ListAccounts,
      callback);
};


/**
 * @param {!proto.vseth.sip.guthaben.ListAccountsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.vseth.sip.guthaben.ListAccountsResponse>}
 *     Promise that resolves to the response
 */
proto.vseth.sip.guthaben.GuthabenPromiseClient.prototype.listAccounts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/vseth.sip.guthaben.Guthaben/ListAccounts',
      request,
      metadata || {},
      methodDescriptor_Guthaben_ListAccounts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.vseth.sip.guthaben.GetAccountRequest,
 *   !proto.vseth.sip.guthaben.Account>}
 */
const methodDescriptor_Guthaben_GetAccount = new grpc.web.MethodDescriptor(
  '/vseth.sip.guthaben.Guthaben/GetAccount',
  grpc.web.MethodType.UNARY,
  proto.vseth.sip.guthaben.GetAccountRequest,
  proto.vseth.sip.guthaben.Account,
  /**
   * @param {!proto.vseth.sip.guthaben.GetAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vseth.sip.guthaben.Account.deserializeBinary
);


/**
 * @param {!proto.vseth.sip.guthaben.GetAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.vseth.sip.guthaben.Account)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.vseth.sip.guthaben.Account>|undefined}
 *     The XHR Node Readable Stream
 */
proto.vseth.sip.guthaben.GuthabenClient.prototype.getAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/vseth.sip.guthaben.Guthaben/GetAccount',
      request,
      metadata || {},
      methodDescriptor_Guthaben_GetAccount,
      callback);
};


/**
 * @param {!proto.vseth.sip.guthaben.GetAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.vseth.sip.guthaben.Account>}
 *     Promise that resolves to the response
 */
proto.vseth.sip.guthaben.GuthabenPromiseClient.prototype.getAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/vseth.sip.guthaben.Guthaben/GetAccount',
      request,
      metadata || {},
      methodDescriptor_Guthaben_GetAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.vseth.sip.guthaben.CreateAccountRequest,
 *   !proto.vseth.sip.guthaben.Account>}
 */
const methodDescriptor_Guthaben_CreateAccount = new grpc.web.MethodDescriptor(
  '/vseth.sip.guthaben.Guthaben/CreateAccount',
  grpc.web.MethodType.UNARY,
  proto.vseth.sip.guthaben.CreateAccountRequest,
  proto.vseth.sip.guthaben.Account,
  /**
   * @param {!proto.vseth.sip.guthaben.CreateAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vseth.sip.guthaben.Account.deserializeBinary
);


/**
 * @param {!proto.vseth.sip.guthaben.CreateAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.vseth.sip.guthaben.Account)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.vseth.sip.guthaben.Account>|undefined}
 *     The XHR Node Readable Stream
 */
proto.vseth.sip.guthaben.GuthabenClient.prototype.createAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/vseth.sip.guthaben.Guthaben/CreateAccount',
      request,
      metadata || {},
      methodDescriptor_Guthaben_CreateAccount,
      callback);
};


/**
 * @param {!proto.vseth.sip.guthaben.CreateAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.vseth.sip.guthaben.Account>}
 *     Promise that resolves to the response
 */
proto.vseth.sip.guthaben.GuthabenPromiseClient.prototype.createAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/vseth.sip.guthaben.Guthaben/CreateAccount',
      request,
      metadata || {},
      methodDescriptor_Guthaben_CreateAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Guthaben_CheckLiveliness = new grpc.web.MethodDescriptor(
  '/vseth.sip.guthaben.Guthaben/CheckLiveliness',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.vseth.sip.guthaben.GuthabenClient.prototype.checkLiveliness =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/vseth.sip.guthaben.Guthaben/CheckLiveliness',
      request,
      metadata || {},
      methodDescriptor_Guthaben_CheckLiveliness,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.vseth.sip.guthaben.GuthabenPromiseClient.prototype.checkLiveliness =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/vseth.sip.guthaben.Guthaben/CheckLiveliness',
      request,
      metadata || {},
      methodDescriptor_Guthaben_CheckLiveliness);
};


module.exports = proto.vseth.sip.guthaben;

