// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import {
  ReactNode
} from "react"

import {
  LoadingOverlay
} from "@mantine/core"

import {
  useAppSelector
} from "../hooks/useAppSelector"

import {
  useAppDispatch
} from "../hooks/useAppDispatch"


// Imports from this projects
// Pages, Components, Features, Hooks
import {
  getAuthStateFromToken,
  selectTokens,
  setAuthState
} from "../features/auth/authSlice"

import { AuthProvider } from "../features/auth/AuthProvider"


//Define interface
type CustomAuthProviderProps = {
  children: ReactNode
}


/**
 * The AdminPage component renders the admin page of the application.
 */
export const CustomAuthProvider = (props: CustomAuthProviderProps) => {
  const tokens = useAppSelector(selectTokens)
  const dispatch = useAppDispatch()


  return (
      <AuthProvider
          setAuthState ={(authState) => {
            dispatch(setAuthState(authState));
          }}
          authState={getAuthStateFromToken(tokens)}
          LoadingComponent={<LoadingOverlay visible={true}/>}
      >
        {props.children}
      </AuthProvider>
  )
}
