import { Button, Card, Center, Container, Group, LoadingOverlay, Skeleton } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import { getUserAccountName, useCreateTransactionMutation, useGetAccountQuery } from "../features/guthaben/guthabenSlice";
import { selectUserInfo, selectUserSub } from "../features/auth/authSlice";
import { Text } from "@mantine/core";
import { centsToCHFString, timeObjToMessage } from "../util/proto";
import { Transaction } from "../proto/vseth/sip/guthaben/guthaben_pb";
import { CreateTransactionModal } from "../features/guthaben/CreateTransactionModal";
import { useDisclosure } from "@mantine/hooks";
import { useTranslation } from "react-i18next";

export const GuthabenPage: React.FC = () => {
    const { t } = useTranslation();
    const userSub = useSelector(selectUserSub);
    const { data: uga, isLoading: loadingAccount } = useGetAccountQuery({ account: getUserAccountName(userSub || "") }, { skip: !userSub });
    const userBalance = uga?.balance;

    const [type, setType] = React.useState<Transaction.Type>(Transaction.Type.TOPUP);

    const [createTransaction, { isLoading }] = useCreateTransactionMutation();

    const [opened, { open, close }] = useDisclosure(false);

    const createTransactionModal =
        <CreateTransactionModal opened={opened} type={type} onClose={() => { close() }} onSubmit={(values) => {
            createTransaction({ transaction: { ...values } }).unwrap().then((tx) => {
                console.log(tx);
                if (tx.checkoutUrl || tx.checkoutUrl !== "") {
                    window.location.assign(tx.checkoutUrl);
                }
            });
            close();
        }} />;

    const topUp = () => {
        setType(Transaction.Type.TOPUP);
        open();
    };

    const payOut = () => {
        setType(Transaction.Type.PAYOUT);
        open();
    };

    const loadingNow = (loadingAccount || isLoading || !userSub);

    return (
        <Container>
            <section className="mb-5">
                <h1 className="mb-3">VSETH Guthaben</h1>
                <LoadingOverlay visible={loadingNow} />
                {createTransactionModal}
                {loadingNow ?
                    <Skeleton height={100} /> :
                    <Center>
                        <Card shadow="sm" padding="lg" radius="md" withBorder>
                            <Text align="center" weight={700} size="xl">
                                {t("Guthaben.yourBalance")}
                            </Text>
                            <Text align="center" weight={400} size={60}>
                                {centsToCHFString(userBalance?.cents || 0)}
                            </Text>
                            <Card.Section withBorder inheritPadding py="lg">
                            <Group>
                                <Button onClick={payOut} fullWidth>Pay out</Button>
                                <Button onClick={topUp} fullWidth>Top up</Button>
                            </Group>
                            </Card.Section>
                        </Card>
                    </Center>
                }
            </section>
        </Container>
    );
};
