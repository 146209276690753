import { configureStore, Action } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";

import rootReducer, { RootState } from "./rootReducer";
import { PeoplePromiseClient } from "../proto/sip/people/people_grpc_web_pb";
import { GuthabenPromiseClient } from "../proto/vseth/sip/guthaben/guthaben_grpc_web_pb";
import { gtcApi } from "../features/gtc/gtcSlice";
import { userApi } from "../features/user/userSlice";
import { guthabenApi } from "../features/guthaben/guthabenSlice";

/**
 * Construct the redux store used in the application.
 */

// hostname: https://people.api.getsip.ethz.ch:443
const client = new PeoplePromiseClient(
  "https://people.web.api.getsip.ethz.ch:443",
  {},
  {}
);

/*
const customizedMiddleware = getDefaultMiddleware({
  thunk: {
    extraArgument: client,
  },
});*/

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    thunk: {
      extraArgument: client,
    }
  }).concat(gtcApi.middleware).concat(userApi.middleware).concat(guthabenApi.middleware),
});

if (process.env.NOVE_ENV === "development" && (module as any).hot) {
  (module as any).hot.accept("./rootReducer.ts", () => {
    const newRootReducer = require("./rootReducer.ts").default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<
  void,
  RootState,
  PeoplePromiseClient,
  Action<string>
>;

export default store;
