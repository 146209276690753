import { useAuth } from "./useAuth";
import { Group, Text } from '@mantine/core';

export interface ProtectedRouteProps {
	children?: any;
}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
	const { authState, login } = useAuth();

	// log in users that are not authenticated
	if (!authState.isAuthenticated) {
    login();
		return (
			<Group>
				<Text>Please Log in to continue</Text>
			</Group>
		);
	}

	return children;
};
