import { BaseQueryFn, createApi, fetchBaseQuery, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react';
import { GuthabenPromiseClient } from '../../proto/vseth/sip/guthaben/guthaben_grpc_web_pb';
import { Empty } from '../../proto/google/protobuf/empty_pb';
import { Account, ConfirmTransactionRequest, CreateAccountRequest, CreateTransactionRequest, GetAccountRequest, GetTransactionRequest, ListAccountsRequest, ListTransactionsRequest, Transaction } from '../../proto/vseth/sip/guthaben/guthaben_pb';
import { Money } from '../../proto/vseth/type/money_pb';
import { getAuthMetadata, timeObjToMessage } from '../../util/proto';
import { GrpcBaseQueryArgs, GrpcQueryArgs, Meta } from '../grpcBase';
import { AuthSliceRoot } from '../auth/authSlice';
import { PeoplePromiseClient } from '../../proto/sip/people/people_grpc_web_pb';
import { Metadata } from 'grpc-web';

const guthabenClient = new GuthabenPromiseClient(
  "https://guthaben.web.vseth.staging-sip.ethz.ch:443",
  {},
  {}
);

interface GetAccountArgs {
  account: string;
}

interface ListTransactionsArgs {
  account: string;
  filter: any;
  order_by: any;
  token: string;
  pageSize: number;
}

interface GetTransactionArgs {
  transaction: string;
}

interface CreateTransactionArgs {
  transaction: Transaction.AsObject;
}

interface ConfirmTransactionArgs {
  transaction: string;
  account: string;
}

interface ListAccountsArgs {
}

interface CreateAccountArgs {
}

const getGrpcBaseQuery =
  ({
    client,
    prepareMetadata,
  }: GrpcBaseQueryArgs<GuthabenPromiseClient>): BaseQueryFn<
    GrpcQueryArgs<GuthabenPromiseClient>,
    unknown,
    FetchBaseQueryError,
    {},
    Meta & FetchBaseQueryMeta
  > =>
    async (req, api) => {
      let metadata: Metadata = {};
      if (prepareMetadata) {
        metadata = prepareMetadata(metadata, api);
      }

      const res = client[req.method](req.message as any, metadata);

      return res
        .then((result) => {
          return { data: result };
        })
        .catch((err) => {
          return { error: err };
        });
    };

export const guthabenApi = createApi({
  reducerPath: 'guthabenApi',
  baseQuery: getGrpcBaseQuery({
    client: guthabenClient,
    prepareMetadata: (metadata, { getState }) => {
      const token = (getState() as AuthSliceRoot).auth.tokens?.token;
      return {
        ...metadata,
        ...getAuthMetadata(token),
      };
    },
  }),
  tagTypes: ["Transactions"],
  endpoints: (builder) => ({
    listTransactions: builder.query<Transaction.AsObject[], ListTransactionsArgs>({
      query: ({ account, filter, order_by, token, pageSize }) => {
        const message = new ListTransactionsRequest();
        message.setAccount(account);
        message.setFilter(filter);
        message.setOrderBy(order_by);
        message.setPageToken(token);
        message.setPageSize(pageSize);


        return {
          method: 'listTransactions',
          message: message,
        }
      },
      transformResponse: (response: Transaction[]) => response.map((tx) => tx.toObject()),
      providesTags: (res: Transaction.AsObject[] | undefined) => res ? res.map((t) => ({ type: "Transactions", id: t.name }))
        : [],
    }),
    getTransaction: builder.query<Transaction.AsObject, GetTransactionArgs>({
      query: ({ transaction }) => {
        const message = new GetTransactionRequest();
        message.setTransaction(transaction);

        return {
          method: 'getTransaction',
          message: message,
        }
      },
      transformResponse: (response: Transaction) => response.toObject(),
      providesTags: ((res: Transaction.AsObject | undefined) => res ? [{ type: "Transactions", id: res.name }] : []),
    }),
    createTransaction: builder.mutation<Transaction.AsObject, CreateTransactionArgs>({
      query: ({ transaction }) => {
        const message = new CreateTransactionRequest();
        const transactionMessage = new Transaction();
        const amountMessage = new Money();
        amountMessage.setCents(transaction.amount?.cents || 0);
        amountMessage.setCurrencyCode(transaction.amount?.currencyCode || "CHF");
        transactionMessage.setAccount(transaction.account);
        if (transaction.expireTime) {
          transactionMessage.setExpireTime(
            timeObjToMessage(transaction.expireTime)
          );
        }
        if (transaction.status) {
          transactionMessage.setStatus(transaction.status);
        }
        transactionMessage.setType(transaction.type);
        transactionMessage.setAmount(amountMessage);
        if (transaction.order) {
          transactionMessage.setOrder(transaction.order);
        }
        if (transaction.gateway) {
          transactionMessage.setGateway(transaction.gateway);
        }
        if (transaction.bill) {
          transactionMessage.setBill(transaction.bill);
        }
        message.setTransaction(transactionMessage);

        return {
          method: 'createTransaction',
          message: message,
        }
      },
      transformResponse: (response: Transaction) => response.toObject(),
      invalidatesTags: ((res: Transaction.AsObject | undefined) => res ? [{ type: "Transactions", id: res.name }] : []),
    }),
    confirmTransaction: builder.mutation<Transaction.AsObject, ConfirmTransactionArgs>({
      query: ({ transaction, account }) => {
        const message = new ConfirmTransactionRequest();
        message.setTransaction(transaction);
        message.setAccount(account);

        return {
          method: 'confirmTransaction',
          message: message,
        }
      },
      transformResponse: (response: Transaction) => response.toObject(),
      invalidatesTags: ((res: Transaction.AsObject | undefined) => res ? [{ type: "Transactions", id: res.name }] : []),
    }),
    listAccounts: builder.query<Account.AsObject[], ListAccountsArgs>({
      query: ({ }) => {
        // TODO: Implement
        const message = new ListAccountsRequest();

        return {
          method: 'listAccounts',
          message: message,
        }
      },
      transformResponse: (response: Account[]) => response.map(a => a.toObject()),
    }),
    getAccount: builder.query<Account.AsObject, GetAccountArgs>({
      query: ({ account }) => {
        const message = new GetAccountRequest();
        message.setAccount(account);
        return {
          method: 'getAccount',
          message: message,
        }
      },
      transformResponse: (response: Account) => response.toObject(),
    }),
    createAccount: builder.mutation<Account.AsObject, CreateAccountArgs>({
      query: (args) => {
        // TODO: Implement
        const message = new CreateAccountRequest();

        return {
          method: 'createAccount',
          message: message,
        }
      },
      transformResponse: (response: Account) => response.toObject(),
    }),
    checkLiveliness: builder.query<Empty.AsObject, void>({
      query: () => {
        const message = new Empty();
        return {
          method: 'checkLiveliness',
          message: message,
        }
      },
      transformResponse: (response: any) => response,
    }),
  }),
});

export const {
  useListTransactionsQuery,
  useGetTransactionQuery,
  useCreateTransactionMutation,
  useConfirmTransactionMutation,
  useListAccountsQuery,
  useGetAccountQuery,
  useCreateAccountMutation,
  useCheckLivelinessQuery,
} = guthabenApi;

export const getUserAccountName = (sub: string) => `accounts/${sub}`;