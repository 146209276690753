import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Anchor, Badge, Button, Card, Container, LoadingOverlay, Text } from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import { getUserAccountName, useConfirmTransactionMutation, useGetTransactionQuery } from "../features/guthaben/guthabenSlice";
import { selectUserSub } from "../features/auth/authSlice";
import { Transaction } from "../proto/vseth/sip/guthaben/guthaben_pb";
import { centsToCHFString, transactionStatusToString, transactionTypeToString } from "../util/proto";
import { BillIcon, CheckIcon } from "vseth-canine-ui";

interface Props { };

// TransactionPage shows a transaction and different actions to interact with it.
export const TransactionPage: React.FC<Props> = (props) => {
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();

    const userSub = useSelector(selectUserSub);
    const { data: tx, isLoading: isLoadingGetTx } = useGetTransactionQuery({ transaction: "transactions/" + id || "" }, { skip: !id });
    const userAccountName = getUserAccountName(userSub || "");
    const isUsersTransaction = userAccountName === tx?.account;

    const [confirmTransaction, { isLoading: isLoadingConfirmation }] = useConfirmTransactionMutation();
    const isLoading = isLoadingConfirmation || isLoadingGetTx;

    const navigate = useNavigate();

    const canRetry = tx?.status === Transaction.Status.UNCONFIRMED;
    const isConfirmed = tx?.status === Transaction.Status.CONFIRMED;
    const canConfirm = !isConfirmed && isUsersTransaction;

    const statusBadgeIcon = tx?.status === Transaction.Status.CONFIRMED ? <CheckIcon color="teal" /> : <BillIcon color="red" />;
    const statusBadge = tx ? (
        <Badge size="xl" radius="xl"
            leftSection={statusBadgeIcon}
            color={tx.status === Transaction.Status.CONFIRMED ? "teal" : "red"}>
            {t("TransactionPage.transaction")} {transactionStatusToString(tx.status)}
        </Badge>
    ) : null;

    const transactionDetails = tx ? (
        <Card>
            {statusBadge}
            <Text>{t("TransactionPage.amount")}: {tx.amount ? centsToCHFString(tx.amount.cents) : "No amount"}</Text>
            <Text>{t("TransactionPage.type")}: {transactionTypeToString(tx.type)}</Text>
            <Text>{t("TransactionPage.status")}: {transactionStatusToString(tx.status)} {!isUsersTransaction && "This transaction was not requested by you."}</Text>
            <Button disabled={!canConfirm} onClick={() => {
                confirmTransaction({ transaction: tx.name, account: userAccountName });
            }
            }>{t("TransactionPage.confirmTransaction")}</Button>

        </Card>
    ) : null;

    console.log(tx);

    const redirectTo = (tx && tx.status === Transaction.Status.CONFIRMED && tx.checkoutUrl !== "") && (tx.checkoutUrl);
    if (redirectTo) {
        console.log("Redirecting to: " + redirectTo);
        window.location.assign(redirectTo);
    }

    return (
        <Container>
            <LoadingOverlay visible={isLoading} />
            <section className="mb-5">
                <h1 className="mb-3">{t("TransactionPage.title")}</h1>
                {transactionDetails}
                {canRetry && <Anchor href={tx?.checkoutUrl}>{t("TransactionPage.checkoutAgain")}</Anchor>}
                {tx?.type === Transaction.Type.TOPUP && isUsersTransaction && isConfirmed &&
                    <Button onClick={() => navigate("/pay/guthaben")}>{t("TransactionPage.continue")}</Button>
                }
            </section>
        </Container>
    );
};
