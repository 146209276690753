import { Modal } from "@mantine/core";
import { Transaction } from "../../proto/vseth/sip/guthaben/guthaben_pb";
import { useForm } from '@mantine/form';
import { getUserAccountName } from "./guthabenSlice";
import { selectUserSub } from "../auth/authSlice";
import { useSelector } from "react-redux";
import { NumberInput, Button } from '@mantine/core';

interface CreateTransactionModalProps {
  opened: boolean;
  type: Transaction.Type;
  onClose: () => void;
  onSubmit: (transaction: Transaction.AsObject) => void;
}

const minPayoutValue = 2000;

const mapTypeToTitle = (type: Transaction.Type) => {
  if (type === Transaction.Type.SPEND) {
    return "Create Payment";
  } else if (type === Transaction.Type.TOPUP) {
    return "Top up balance";
  } else if (type === Transaction.Type.PAYOUT) {
    return "Pay out balance";
  }
  return "Unsupported Operation";
};

export const CreateTransactionModal: React.FC<CreateTransactionModalProps> = ({ opened, type, onClose, onSubmit }) => {

  const form = useForm<Transaction.AsObject>({
    initialValues: {
      name: "",
      account: getUserAccountName(useSelector(selectUserSub) || ""),
      order: "",
      gateway: "",
      bill: "",
      amount: {
        cents: minPayoutValue,
        currencyCode: "CHF"
      },
      status: 0,
      createTime: undefined,
      updateTime: undefined,
      type: type,
      checkoutUrl: "",
      url: "",
    },
  });

  const title = mapTypeToTitle(type);
  return (
    <Modal opened={opened} onClose={onClose} title={title}>
      <form onSubmit={form.onSubmit((values: Transaction.AsObject) => onSubmit(values))}>
        <NumberInput label="Amount" required
          defaultValue={minPayoutValue}
          min={minPayoutValue}
          step={100}
          parser={(value: string) => Number(parseFloat(value.replace(/[^\.,\d]/g, "")) * 100).toString()}
          formatter={(value: string) =>
            !Number.isNaN(parseFloat(value))
              ? `${form.values.amount?.currencyCode} ${(parseFloat(value) / 100).toFixed(2)}`
              : `${form.values.amount?.currencyCode} `
          }
          {...form.getInputProps("amount.cents")} />
        <Button type="submit" mt="md">
          Submit
        </Button>
      </form>
    </Modal>
  );
};
