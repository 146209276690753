import * as google_date_pb from "../proto/google/type/date_pb";
import * as google_timestamp_pb from "google-protobuf/google/protobuf/timestamp_pb";

import { BankAccount, PostalAddress, User } from "../features/user/userSlice";
import {
  User as UserMessage,
  PostalAddress as PostalAddressMessage,
  BankAccount as BankAccountMessage,
} from "../proto/sip/people/people_pb";
import { Transaction } from "../proto/vseth/sip/guthaben/guthaben_pb";

export function getAuthMetadata(token?: string) {
  return {
    Authorization: `Bearer ${token}`,
  };
}

export function userToUserMessage(updatedUser: User): UserMessage {
  const userMessage = new UserMessage();
  userMessage.setUsername(updatedUser.username);
  userMessage.setEmail(updatedUser.email);
  userMessage.setAlternativeEmail(updatedUser.alternativeEmail);
  userMessage.setGivenName(updatedUser.givenName);
  userMessage.setFamilyName(updatedUser.familyName);
  userMessage.setTelephone(updatedUser.telephone);
  userMessage.setBirthdate(dateToDateMessage(updatedUser.birthdate));

  if (updatedUser.postalAddress) {
    userMessage.setPostalAddress(
      addressToAddressMessage(updatedUser.postalAddress)
    );
  }

  if (updatedUser.bankAccount) {
    userMessage.setBankAccount(
      bankAccountToBankAccountMessage(updatedUser.bankAccount)
    );
  }

  return userMessage;
}

export function addressToAddressMessage(
  address: PostalAddress
): PostalAddressMessage {
  const addressMessage = new PostalAddressMessage();

  addressMessage.setStreetAddress(address.streetAddress);
  addressMessage.setPostalCode(address.postalCode);
  addressMessage.setLocality(address.locality);
  addressMessage.setCountry(address.country);

  return addressMessage;
}

export function bankAccountToBankAccountMessage(
  bankAccount: BankAccount
): BankAccountMessage {
  const bankAccountMessage = new BankAccountMessage();
  bankAccountMessage.setAccountHolderName(bankAccount.accountHolderName);
  bankAccountMessage.setBankName(bankAccount.bankName);
  bankAccountMessage.setBic(bankAccount.bic);
  bankAccountMessage.setIban(bankAccount.iban);

  if (bankAccount.bankingAddress) {
    bankAccountMessage.setBankingAddress(
      addressToAddressMessage(bankAccount.bankingAddress)
    );
  }
  return bankAccountMessage;
}

// formatDate formats a date as a string in the form needed by the date picker field
// TODO: should use to string method of JS date
export function formatDate(date?: google_date_pb.Date.AsObject): string {
  if (!date) {
    return "Not specified";
  }

  const jsDate = dateObjToLocalDate(date);

  if (!jsDate) {
    return "Not specified";
  }

  return jsDate.toDateString();
  // let month = '' + (jsDate.getMonth() + 1);
  // let day = '' + jsDate.getDate();
  // const year = jsDate.getFullYear();
  //
  // if (month.length < 2)
  //     month = '0' + month;
  // if (day.length < 2)
  //     day = '0' + day;
  //
  // return [year, month, day].join('-');
}

/**
 * Helper method that turns a Date.AsObject into a Date
 * @param date
 */
export const dateToDateMessage = (
  date?: google_date_pb.Date.AsObject
): google_date_pb.Date => {
  const dateMessage = new google_date_pb.Date();

  if (date) {
    dateMessage.setYear(date.year);
    dateMessage.setMonth(date.month);
    dateMessage.setDay(date.day);
  }

  return dateMessage;
};

/*
 * Helper function to turn a protobuf Date.AsObject into Date in local time
 */
export const dateObjToLocalDate = (
  date?: google_date_pb.Date.AsObject
): Date | undefined => {
  if (!date) {
    return undefined;
  }
  const newDate = new Date(0);
  newDate.setFullYear(date.year);
  newDate.setMonth(date.month - 1);
  newDate.setDate(date.day);
  return newDate;
};

/*
 * Helper function to turn a Date into a protobuf Date.AsObject
 */
export const dateToDateObj = (date?: Date): google_date_pb.Date.AsObject => {
  const dateMessage = new google_date_pb.Date();
  if (date) {
    dateMessage.setYear(date.getFullYear());
    dateMessage.setMonth(date.getMonth() + 1);
    dateMessage.setDay(date.getDate());
  }
  return dateMessage.toObject();
};

/**
 * Helper function that turns Timestamp.AsObject into Timestamp
 */
export const timeObjToMessage = (
  time?: google_timestamp_pb.Timestamp.AsObject
): google_timestamp_pb.Timestamp => {
  const timestamp = new google_timestamp_pb.Timestamp();
  if (time) {
    timestamp.setSeconds(time.seconds);
    timestamp.setNanos(time.nanos);
  }
  return timestamp;
};

const transactionTypeToStringMap = {
  [Transaction.Type.TOPUP]: "Top-up",
  [Transaction.Type.PAYOUT]: "Payout",
  [Transaction.Type.SPEND]: "Spend",
  [Transaction.Type.REFUND]: "Refund",
}

/**
 * Helper function that turns a transaction type into a descriptive string
 */
export const transactionTypeToString = (type: Transaction.Type): string => (
  transactionTypeToStringMap[type] || "Unknown"
)

const transactionStatusToStringMap = {
  [Transaction.Status.UNCONFIRMED]: "Unconfirmed",
  [Transaction.Status.CONFIRMED]: "Confirmed",
  [Transaction.Status.CANCELLED]: "Cancelled",
}

/**
 * Helper function that turns a transaction state into a descriptive string
 */
export const transactionStatusToString = (state: Transaction.Status): string => (
  transactionStatusToStringMap[state] || "Unknown"
)

export const centsToCHFString = (cents: number): string => (
  ((cents || 0) / 100).toFixed(2) + " CHF"
)